@import "tw-elements-react/dist/css/tw-elements-react.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 769px) {

  /* Стилизация скроллбара для WebKit-браузеров */
  ::-webkit-scrollbar {
    width: 12px;
    /* ширина для вертикального скроллбара */
    height: 12px;
    /* высота для горизонтального скроллбара */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(85 85 85 / .5);
    /* цвет самого скроллбара */
    border-radius: 6px;
    /* скругление углов */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(85 85 85 / .2);
    /* цвет при наведении */
  }
}

/* Стилизация скроллбара для Firefox */
* {
  scrollbar-width: thin;
  /* "auto" или "thin" */
  scrollbar-color: #888 #f1f1f1;
  /* цвет скроллбара и дорожки */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* В вашем CSS файле */
.custom-checkbox {
  /* ... Ваши стили ... */
}

.custom-checkbox:checked {
  /* ... Ваши стили для состояния :checked ... */
}

code:not([class*=language-]) {
  font-weight: 400;
  color: #b93993;
  line-height: inherit;
  font-size: inherit;
  background-color: #f1f3f8;
  padding: 0.1rem 0.4rem;
  margin: 0 0.5rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .08);
  border-radius: 0.3rem;
}

.layout__area--top {
  display: none;
}

[data-name="legend"] {
  display: none;
}

iframe {
  min-height: 500px;
}

.tab__flag {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 16px;
  height: 16px;
}

.tab__flag__selector {
  width: 28px;
  height: 28px;
}

@media screen and (max-width: 767px) {

  .tab__flag__selector {
    width: 18px;
    height: 18px;
  }
  
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

h1 {
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

.w-row:before,
.w-row:after {
  content: " ";
  display: table;
}

.w-row:after {
  clear: both;
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.w-col-3 {
  width: 25%;
}

@media screen and (max-width:767px) {
  .w-row {
    margin-left: 0;
    margin-right: 0;
  }

  .w-col {
    width: 100%;
    left: auto;
    right: auto;
  }
}

@media screen and (max-width:479px) {
  .w-col {
    width: 100%;
  }
}

h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 42px;
  line-height: 54px;
  font-weight: 400;
}

.divider {
  height: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #eee;
}

.style-label {
  color: #bebebe;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.row {
  margin-bottom: 50px;
}

.number-card-number {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: 300;
}

.tagline {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.tagline.number-card-currency {
  color: #fff;
}

.basic-column {
  padding-right: 5px;
  padding-left: 5px;
}

.number-card {
  padding: 22px 30px;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(270deg, #1991eb, #1991eb);
  background-image: linear-gradient(180deg, #1991eb, #1991eb);
}

.number-card.number-card-content3 {
  background-image: -webkit-linear-gradient(270deg, #ed629a, #c850c0);
  background-image: linear-gradient(180deg, #ed629a, #c850c0);
}

.number-card.number-card-content4 {
  background-image: -webkit-linear-gradient(270deg, #ff8308, #fd4f00);
  background-image: linear-gradient(180deg, #ff8308, #fd4f00);
}

.number-card.number-card-content2 {
  display: block;
  background-image: -webkit-linear-gradient(270deg, #17cec4, #17cec4 0%, #08aeea);
  background-image: linear-gradient(180deg, #17cec4, #17cec4 0%, #08aeea);
  color: #333;
}

.number-card.number-card-content1 {
  background-image: -webkit-linear-gradient(270deg, #7042bf, #3023ae);
  background-image: linear-gradient(180deg, #7042bf, #3023ae);
}

.number-card-progress-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.number-card-divider {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 14px;
  background-color: hsla(0, 0%, 100%, .15);
}

.number-card-dollars {
  color: hsla(0, 0%, 100%, .8);
  font-size: 16px;
  line-height: 24px;
}

.number-card-progress {
  color: #fff;
  text-align: right;
}

@media (max-width: 991px) {
  .number-card-number {
    font-size: 30px;
  }

  .number-card {
    padding-top: 12px;
    padding-bottom: 16px;
  }
}

.ps-menu-button {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 7px;
  white-space: nowrap;
  font-weight: bold;
}

.ps-menu-icon {
  margin-right: 0 !important;
}

.ps-sidebar-root {
  width: 60px !important;
  min-width: 60px !important;
}


@keyframes pulseArrow {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-10px) scale(1.1);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid rgba(0, 255, 0, 0.5);
  position: absolute;
  top: 10px;
  /* Адаптируйте это значение в зависимости от нужного положения над графиком */
  left: 50%;
  transform: translateX(-50%);
  animation: pulseArrow 2s infinite;
}

.detail-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.detail-container.open {
  max-height: 1000px;
  /* Подбери значение в зависимости от ожидаемой высоты контента */
}

.step {
  position: relative;
}

.step::before {
  content: '';
  width: 10px;
  height: 10px;
  border: 2px solid gray;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 4px;
  height: 100%;
  border-left: 2px dashed gray;
}